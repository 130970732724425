<div class="month-picker-container">
  <div class="month-picker-child">
    <div class="defined-range-item" (click)="resolvePredefinedRange('Last 3 Months')">Last 3 Months</div>
    <div class="defined-range-item" (click)="resolvePredefinedRange('Last 6 Months')">Last 6 Months</div>
    <div class="defined-range-item" (click)="resolvePredefinedRange('Last 12 Months')">Last 12 Months</div>
    <div class="defined-range-item" (click)="resolvePredefinedRange('Year-To-Date')">Year-To-Date</div>
    <div class="defined-range-item" (click)="resolvePredefinedRange('Last Year')">Last Year</div>
  </div>
  <div class="month-picker-child">
    <div class="year-navigation">
      <button (click)="changeYear(-1)">&#8249;</button>
      <span>{{ currentYear }}</span>
      <button (click)="changeYear(1)">&#8250;</button>
    </div>
    <div class="months">
      <div
        *ngFor="let month of months; let i = index"
        class="month"
        [class.selected]="(selectedStartMonth && selectedStartMonth.month === i && selectedStartMonth.year === currentYear) || (selectedEndMonth && selectedEndMonth.month === i && selectedEndMonth.year === currentYear)"
        (click)="selectMonth(i)"
      >
        {{ month }}
      </div>
    </div>
  </div>

  <div *ngIf="selectedStartMonth && selectedEndMonth" class="selected-range">
    Selected Range: {{ getSelectedRange() }}
  </div>
</div>
