/**
 * Formats a number as a string with a prefix indicating the magnitude.
 *
 * @return {string} The formatted number as a string.
 */
Number.prototype.abbreviate = function(): string | number {
  const num = isNaN(this) ? 0 : this;
  //round to 2 decimal places
  const numRounded = Math.round(num * 100) / 100;
  if (numRounded < 10000) {
    return numRounded.toString();
  } else if (numRounded < 1e6) {
    return (numRounded / 1e3).toFixed(1) + 'K';
  } else if (numRounded < 1e9) {
    return (numRounded / 1e6).toFixed(1) + 'M';
  } else if (numRounded < 1e12) {
    return (numRounded / 1e9).toFixed(1) + 'B';
  } else {
    return (numRounded / 1e12).toFixed(1) + 'T';
  }
};

export function getDateRangeMonths(range: string): string[] {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  let startYear: number, startMonth: number, endYear: number, endMonth: number;

  switch (range.toLowerCase()) {
    case 'this month':
      startYear = endYear = currentYear;
      startMonth = endMonth = currentMonth;
      break;
    case 'last month':
      if (currentMonth === 0) {
        startYear = endYear = currentYear - 1;
        startMonth = endMonth = 11;
      } else {
        startYear = endYear = currentYear;
        startMonth = endMonth = currentMonth - 1;
      }
      break;
    case 'year-to-date':
      startYear = currentYear;
      startMonth = 0;
      endYear = currentYear;
      endMonth = currentMonth;
      break;
    case 'last year':
      startYear = currentYear - 1;
      startMonth = 0;
      endYear = currentYear - 1;
      endMonth = 11;
      break;
    case 'last 3 months':
      endYear = currentYear;
      endMonth = currentMonth;
      if (currentMonth >= 2) {
        startYear = currentYear;
        startMonth = currentMonth - 2;
      } else {
        startYear = currentYear - 1;
        startMonth = 12 + currentMonth - 2;
      }
      break;
    case 'last 6 months':
      endYear = currentYear;
      endMonth = currentMonth;
      if (currentMonth >= 5) {
        startYear = currentYear;
        startMonth = currentMonth - 5;
      } else {
        startYear = currentYear - 1;
        startMonth = 12 + currentMonth - 5;
      }
      break;
    case 'last 12 months':
      endYear = currentYear;
      endMonth = currentMonth;
      if (currentMonth >= 11) {
        startYear = currentYear;
        startMonth = currentMonth - 11;
      } else {
        startYear = currentYear - 1;
        startMonth = 12 + currentMonth - 11;
      }
      break;
    default:
      // Parse the default case assuming the format is "January 2024 - May 2024"
      const parts = range.split(' - ');
      if (parts.length === 2) {
        const startParts = parts[0].split(' ');
        const endParts = parts[1].split(' ');

        if (startParts.length === 2 && endParts.length === 2) {
          startMonth = monthNames.indexOf(startParts[0]);
          startYear = parseInt(startParts[1]);
          endMonth = monthNames.indexOf(endParts[0]);
          endYear = parseInt(endParts[1]);

          if (startMonth === -1 || endMonth === -1) {
            throw new Error('Invalid month name in date range');
          }
        } else {
          throw new Error('Invalid date range format');
        }
      } else {
        throw new Error('Invalid date range format');
      }
      break;
  }

  const dateRangeMonths = [];
  for (let year = startYear; year <= endYear; year++) {
    const start = year === startYear ? startMonth : 0;
    const end = year === endYear ? endMonth : 11;
    for (let month = start; month <= end; month++) {
      dateRangeMonths.push(`${months[month]}-${year.toString().slice(-2)}`);
    }
  }

  return dateRangeMonths;
}

export function getPreviousPeriod(monthRange) {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const [start, end] = monthRange.split(' - ');
  const [startMonth, startYear] = start.split(' ');
  const [endMonth, endYear] = end.split(' ');

  const startIndex = monthNames.indexOf(startMonth);
  const endIndex = monthNames.indexOf(endMonth);

  let previousStartMonthIndex = startIndex - 2;
  let previousStartYear = parseInt(startYear);
  let previousEndMonthIndex = endIndex - 2;
  let previousEndYear = parseInt(endYear);

  if (previousStartMonthIndex < 0) {
    previousStartMonthIndex += 12;
    previousStartYear -= 1;
  }

  if (previousEndMonthIndex < 0) {
    previousEndMonthIndex += 12;
    previousEndYear -= 1;
  }

  const previousStartMonth = monthNames[previousStartMonthIndex];
  const previousEndMonth = monthNames[previousEndMonthIndex];

  return `${previousStartMonth} ${previousStartYear} - ${previousEndMonth} ${previousEndYear}`;
}

export function resolvePredefinedRange(range: string): string {
  const now = new Date();
  let startMonth: number, startYear: number, endMonth: number, endYear: number;
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  switch (range.toLowerCase()) {
    case 'last 3 months':
      endMonth = now.getMonth();
      endYear = now.getFullYear();
      startMonth = endMonth - 2;
      startYear = endYear;
      if (startMonth < 0) {
        startMonth += 12;
        startYear -= 1;
      }
      break;
    case 'last 6 months':
      endMonth = now.getMonth();
      endYear = now.getFullYear();
      startMonth = endMonth - 5;
      startYear = endYear;
      if (startMonth < 0) {
        startMonth += 12;
        startYear -= 1;
      }
      break;
    case 'last 12 months':
      endMonth = now.getMonth();
      endYear = now.getFullYear();
      startMonth = endMonth - 11;
      startYear = endYear;
      if (startMonth < 0) {
        startMonth += 12;
        startYear -= 1;
      }
      break;
    case 'year-to-date':
      startMonth = 0;
      startYear = now.getFullYear();
      endMonth = now.getMonth();
      endYear = startYear;
      break;
    case 'last year':
      startMonth = 0;
      startYear = now.getFullYear() - 1;
      endMonth = 11;
      endYear = startYear;
      break;
    default:
      return range;
  }

  const startMonthName = months[startMonth];
  const endMonthName = months[endMonth];
 return `${startMonthName} ${startYear} - ${endMonthName} ${endYear}`
}

