<!-- month-picker.component.html -->
<div class="month-picker-container">
  <button (click)="toggleDropdown()" class="mdropdown-toggle">
    Date
    <img ngSrc="assets/images/svg/chev-down.svg" alt="Expand" height="25" width="24">
  </button>
  <div *ngIf="isDropdownOpen" class="month-picker-dropdown">
    <div class="month-picker-child">
      <div class="defined-range-item" *ngIf="showPredefinedRange('Last 3 Months')" (click)="resolvePredefinedRange('Last 3 Months')">Last 3 Months</div>
      <div class="defined-range-item" *ngIf="showPredefinedRange('Last 6 Months')" (click)="resolvePredefinedRange('Last 6 Months')">Last 6 Months</div>
      <div class="defined-range-item" *ngIf="showPredefinedRange('Last 12 Months')" (click)="resolvePredefinedRange('Last 12 Months')">Last 6 Months</div>
      <div class="defined-range-item" *ngIf="showPredefinedRange('Year-To-Date')" (click)="resolvePredefinedRange('Year-To-Date')">Year-To-Date</div>
      <div class="defined-range-item" *ngIf="showPredefinedRange('Last Year')" (click)="resolvePredefinedRange('Last Year')">Last Year</div>
    </div>
    <div class="month-picker-child">
      <div class="year-navigation">
        <button (click)="changeYear(-1)">&#8249;</button>
        <span>{{ currentYear }}</span>
        <button (click)="changeYear(1)">&#8250;</button>
      </div>
      <div class="months">
        <div
          *ngFor="let month of months; let i = index"
          class="month"
          [class.selected]="(selectedStartMonth && selectedStartMonth.month === i && selectedStartMonth.year === currentYear) || (selectedEndMonth && selectedEndMonth.month === i && selectedEndMonth.year === currentYear)"
          (click)="selectMonth(i)"
        >
          {{ month }}
        </div>
      </div>
    </div>
  </div>
</div>
