import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private storageSubject = new Subject<any>();

  watchStorage(): Subject<any> {
    return this.storageSubject;
  }

  getData(key: string): any {
    const data = localStorage.getItem(key);
    return data ? data : null;
  }

  setData(key: string, data: any, shouldRefresh: boolean = true): void {
    localStorage.setItem(key, data);
    if (shouldRefresh) {
      //this.storageSubject.pipe(debounceTime(200)).next({ key, newValue: data });
      this.storageSubject.next({key, newValue: data});
    }
  }

  getBooleanData(key: string): boolean {
    const data = localStorage.getItem(key);
    return data ?  data === 'true' : false;
  }

  getArrayData(key: string): any[] {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : [];
  }

  setObjectData(key: string, data: any, shouldRefresh: boolean = true): void {
    localStorage.setItem(key, JSON.stringify(data));
    if (shouldRefresh) {
      this.storageSubject.next({key, newValue: data});
    }
  }

  getObjectData(key: string): any {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  setArrayData(key: string, data: any[],  shouldRefresh: boolean = true): void {
    localStorage.setItem(key, JSON.stringify(data));
    if (shouldRefresh) {
      this.storageSubject.next({key, newValue: data});
    }
  }

  hasData(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }
}
