import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BoostrapUiModule } from '../boostrap-ui/boostrap-ui.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


const imports = [
  RouterModule,
  NgApexchartsModule,
  BoostrapUiModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule
];
@NgModule({
  declarations: [],
  imports: [
    ...imports
  ],
  exports: imports
})
export class SharedModule { }
