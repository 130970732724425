export interface FilterOption {
  value: string;
  label: string;
}

export interface KPIGroup {
  category: string;
  kpis: FilterOption[];
}

export interface FilterLevel {
  level: string;
  value: string;
  options: FilterOption[];
}

export const KPI_CATEGORIES: KPIGroup[] = [
  {
    category: '-- Select KPI Category --',
    kpis: [],
  },
  {
    category: 'Volumetric',
    kpis: [
      { value: '', label: '-- Select KPI --' },
      { value: 'sales_volume', label: 'Sales Volume' },
      { value: 'sales_value', label: 'Sales Value' },
    ],
  },
  {
    category: 'Price',
    kpis: [
      { value: '', label: '-- Select KPI --' },
      { value: 'unit_price', label: 'Unit Price' },
      { value: 'price_kg_ltr', label: 'Price/kg' },
    ],
  },
  {
    category: 'Numeric Distribution',
    kpis: [
      { value: '', label: '-- Select KPI --' },
      { value: 'numeric_distribution', label: 'Numeric Distribution' },
    ],
  },
  {
    category: 'Weighted Distribution',
    kpis: [
      { value: '', label: '-- Select KPI --' },
      { value: 'weighted_distribution', label: 'Weighted Distribution' },
    ],
  },
  {
    category: 'RoS Volume',
    kpis: [
      { value: '', label: '-- Select KPI --' },
      { value: 'ros_volume', label: 'RoS Volume' },
    ],
  },
  {
    category: 'RoS Value',
    kpis: [
      { value: '', label: '-- Select KPI --' },
      { value: 'ros_value', label: 'RoS Value' },
    ],
  },
];

export const FILTER_LEVELS: FilterLevel[] = [
  {
    level: '-- Select Level --',
    value: '',
    options: [],
  },
  {
    level: 'category',
    value: 'category',
    options: [],
  },
  {
    level: 'segment',
    value: 'segment',
    options: [],
  },
  {
    level: 'manufacturer',
    value: 'manufacturer',
    options: [],
  },
  {
    level: 'brand',
    value: 'brand',
    options: [],
  },
  {
    level: 'SKU/Item',
    value: 'item_name',
    options: [],
  },
];
