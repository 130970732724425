import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';


const custImports = [
  TooltipModule,
  BsDropdownModule
]
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...custImports
  ],
  exports: custImports
})
export class BoostrapUiModule { }
