import {getDateRangeMonths, getPreviousPeriod, resolvePredefinedRange} from "./utils";

export function calculatePercentageDifference(currentValue: number, previousValue: number): number {
  if (typeof currentValue !== 'number' || typeof previousValue !== 'number' || isNaN(currentValue) || isNaN(previousValue)) {
    return 0.0;
  }

  if (previousValue === 0) {
    return 100.0;
  }

  const percentageDifference = ((currentValue - previousValue) / previousValue) * 100;

  return parseFloat(percentageDifference.toFixed(1));
}

export function findFieldSummary(data: any[], columnName: string, period: string, filterBy: string[], country: string, category: string, aggregator: string = 'sum'): number[] {
  let currentMonthValue = 0.0;
  let prevMonthValue = 0.0;
  let thisPeriod = getDateRangeMonths(period);
  let lastPeriod = getDateRangeMonths(getPreviousPeriod(resolvePredefinedRange(period)));

  //first droop some columns based on filter
  data = data.filter(item => {
    return item.country === country && item.category === category
  });

  switch (aggregator) {
    case 'sum':
      data.forEach(item => {
        if (thisPeriod.includes(item.period) && filterBy.includes(item.MBD)){
          currentMonthValue += parseFloat(item[columnName]);
        } else if (lastPeriod.includes(item.period) && filterBy.includes(item.MBD)) {
          prevMonthValue += parseFloat(item[columnName]);
        }
      });
      return [currentMonthValue, calculatePercentageDifference(currentMonthValue, prevMonthValue)];

    case 'count':
      data.forEach(item => {
        if (thisPeriod.includes(item.period) && filterBy.includes(item.MBD)){
          currentMonthValue += 1;
        } else if (lastPeriod.includes(item.period) && filterBy.includes(item.MBD)) {
          prevMonthValue += 1;
        }
      });
      return [currentMonthValue, calculatePercentageDifference(currentMonthValue, prevMonthValue)];

    case 'avg':
      let countCur = 0;
      let countPrev = 0;
      data.forEach(item => {
        if (thisPeriod.includes(item.period) && filterBy.includes(item.MBD)){
          currentMonthValue += parseFloat(item[columnName]);
          countCur += 1;
        } else if (lastPeriod.includes(item.period) && filterBy.includes(item.MBD)) {
          prevMonthValue += parseFloat(item[columnName]);
          countPrev += 1;
        }
      });
      return [currentMonthValue / countCur, calculatePercentageDifference(currentMonthValue / countCur, prevMonthValue / countPrev)];

    default:
      throw new Error(`Unknown aggregator: ${aggregator}`);
  }
}

type DataRecord = { [key: string]: string | number };
export function createPivotTable(
  data: DataRecord[],
  rowKey: string,
  colKey: string,
  valueKey: string,
  filterColumnKey: string,
  filterColumnValues: string[],
  aggregator: 'sum' | 'count' | 'avg' = 'sum'
) {
  const pivotTable: { [key: string]: { [key: string]: number } } = {};

  const filteredData = filterColumnValues.length > 0
    ? data.filter(d => filterColumnValues.includes(d[filterColumnKey] as string))
    : data;

  filteredData.forEach(item => {
    const row = item[rowKey] as string;
    const col = item[colKey] as string;
    let value = parseFloat(item[valueKey] as string);
    value = isNaN(value) ? 0 : value;

    if (!pivotTable[row]) {
      pivotTable[row] = {};
    }

    if (!pivotTable[row][col]) {
      pivotTable[row][col] = 0;
    }

    pivotTable[row][col] += value;
  });

  // Aggregate the values based on the aggregator function
  for (let row in pivotTable) {
    for (let col in pivotTable[row]) {
      switch (aggregator) {
        case 'sum':
          pivotTable[row][col] = pivotTable[row][col];
          break;
        case 'count':
          pivotTable[row][col] = 1; // Each entry represents a count of 1
          break;
        case 'avg':
          // Calculate average by dividing the sum by the count
          const count = filteredData.filter(item => item[rowKey] === row && item[colKey] === col).length;
          pivotTable[row][col] = parseFloat((pivotTable[row][col] / count).toFixed(2));
          break;
        default:
          throw new Error(`Unknown aggregator: ${aggregator}`);
      }
    }
  }

  //console.log('pivotTable', pivotTable)
  return pivotTable;
}

export function getUniqueColumnValuesFromDataset(dataset: any[], valueKey: string): string[] {
  let categories = [];
  for (let i = 0; i < dataset.length; i++) {
    if (!categories.includes(dataset[i][valueKey])) {
      categories.push(dataset[i][valueKey]);
    }
  }
  return categories;
}

export function getCascadedValuesFromDataset(dataset: any[], parentColumnKey: string, parentColumnValue: string, childColumnKey: string,): string[] {
  let categories = [];
  for (let i = 0; i < dataset.length; i++) {
    if (dataset[i][parentColumnKey] === parentColumnValue && !categories.includes(dataset[i][childColumnKey])) {
      categories.push(dataset[i][childColumnKey]);
    }
  }
  return categories;
}

function fillMissingMonths(data: any[]): any[] {
  const allMonths = new Set<string>();

  // Step 1: Identify all unique months across all objects
  data.forEach(obj => {
    Object.keys(obj.data).forEach(month => {
      allMonths.add(month);
    });
  });

  // Convert the Set to an Array and sort the months
  const sortedMonths = Array.from(allMonths).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  // Step 2: Fill missing months in each object
  const result = data.map(obj => {
    const filledObj: any = {};
    sortedMonths.forEach(month => {
      filledObj[month] = obj.data.hasOwnProperty(month) ? obj.data[month] : 0.0;
    });
    return {data: filledObj, seriesName: obj.seriesName};
  });

  return result;
}

export function pivotObjectToChartArray(pivotObjectOriginal: any[]): Array<any> {
  let arr = [];
  const pivotObjectTransformed = fillMissingMonths(pivotObjectOriginal);
  for (let obj of pivotObjectTransformed) {
    let ob: any = {};
    let oarr: any[] = [];
    for (let key in obj.data) {
      oarr.push(obj.data[key]);
    }
    ob.data = oarr;
    ob.name = obj.seriesName;
    arr.push(ob);
  }

  return arr;
}

export function pivotObjectKeysToArray(pivotObjectOriginal: any[]): Array<String> {
  const pivotObjectTransformed = fillMissingMonths(pivotObjectOriginal)[0].data;
  //convert object keys to array
  let arr = []
  for (let key in pivotObjectTransformed) {
    arr.push(key)
  }

    return arr
}
type DataStructure = { [category: string]: { [period: string]: number } };

export function filterDataByDateRange(data: DataStructure, range: string, selectedFilter: string = 'All'): { [key: string]: number } {
  const monthsInRange = getDateRangeMonths(range);
  const filteredData: { [key: string]: number } = {};

  if (selectedFilter.toLowerCase() === 'all') {
    const allData: { [key: string]: number } = {};

    Object.values(data).forEach(item => {
      Object.entries(item).forEach(([key, value]) => {
        if (!allData[key]) {
          allData[key] = 0;
        }
        allData[key] += value;
      });
    });

    Object.entries(allData).forEach(([key, value]) => {
      if (monthsInRange.includes(key)) {
        filteredData[key] = parseFloat(value.toFixed(2));
      }
    });
  } else {
    if (data[selectedFilter] !== undefined) {
      Object.entries(data[selectedFilter]).forEach(([key, value]) => {
        if (monthsInRange.includes(key)) {
          filteredData[key] = parseFloat(value.toFixed(2));
        }
      });
    }
  }

  return filteredData;
}

export function abbreviateNumber(value: number, option: string): string {
  if (value === null){
    return 'N/A';
  }
  if (typeof value !== 'number') {
    return 'N/A';
  }

  switch (option.toLowerCase()) {
    case 'absolute':
      return value.toLocaleString(undefined, { maximumFractionDigits: 1 });

    case 'thousand':
      return (value / 1000).toFixed(1) + 'k';

    case 'million':
      return (value / 1000000).toFixed(2) + 'M';

    case 'billion':
      return (value / 1000000000).toFixed(2) + 'B';

    case 'trillion':
      return (value / 1000000000000).toFixed(2) + 'T';

    default:
      throw new Error('Invalid option');
  }
}

export function valueToPercentage(value: number): string {
  return (value * 100).toFixed(1) + '%';
}

export function findMinMaxYears(data = []): { minYear: number; maxYear: number } {
  if (data.length === 0) {
    return { minYear: new Date().getFullYear(), maxYear: new Date().getFullYear() };
  }

  let minYear = Number.MAX_SAFE_INTEGER;
  let maxYear = Number.MIN_SAFE_INTEGER;

  data.forEach(item => {
    const year = parseInt(item.period.split('-')[1], 10) + 2000; // Assumes the year is always 'YY' format and >= 2000
    if (year < minYear) {
      minYear = year;
    }
    if (year > maxYear) {
      maxYear = year;
    }
  });

  return { minYear, maxYear };
}

type ManufacturerData = { [manufacturer: string]: { [month: string]: number } };

export function getTopNContribution(data: ManufacturerData, topN: number, range: string): { [month: string]: { [manufacturer: string]: number } } {
  // Calculate the total sales volume per manufacturer
  const totalSalesVolumePerManufacturer: { [manufacturer: string]: number } = {};
  const monthsInRange = getDateRangeMonths(range);
  const filteredData: { [key: string]: { [key: string]: number } } = {};

  for (const manufacturer in data) {
    if (!filteredData[manufacturer]) {
      filteredData[manufacturer] = {};
    }
    for (const month in data[manufacturer]) {
      if (monthsInRange.includes(month)) {
        filteredData[manufacturer][month] = data[manufacturer][month];
      }
    }
  }

  for (const manufacturer in filteredData) {
    totalSalesVolumePerManufacturer[manufacturer] = Object.values(filteredData[manufacturer]).reduce((sum, value) => sum + value, 0);
  }

  // Sort manufacturers based on total sales volume
  const sortedManufacturers = Object.keys(totalSalesVolumePerManufacturer)
    .sort((a, b) => totalSalesVolumePerManufacturer[b] - totalSalesVolumePerManufacturer[a]);

  // Select top N manufacturers
  if (topN == 0) {
    topN = sortedManufacturers.length;
  }
  const topManufacturers = sortedManufacturers.slice(0, topN);

  // Calculate the percentage contribution for each month for the top N manufacturers
  const monthlyTotalVolumes: { [month: string]: number } = {};
  for (const manufacturer in filteredData) {
    for (const month in filteredData[manufacturer]) {
      if (!monthlyTotalVolumes[month]) {
        monthlyTotalVolumes[month] = 0;
      }
      monthlyTotalVolumes[month] += filteredData[manufacturer][month];
    }
  }

  const topNManufacturersContribution: { [month: string]: { [manufacturer: string]: number } } = {};
  for (const manufacturer of topManufacturers) {
    for (const month in filteredData[manufacturer]) {
      if (!topNManufacturersContribution[month]) {
        topNManufacturersContribution[month] = {};
      }
      topNManufacturersContribution[month][manufacturer] = parseFloat(((filteredData[manufacturer][month] / monthlyTotalVolumes[month]) * 100).toFixed(2));
    }
  }

  return topNManufacturersContribution;
}

type CustomPivotData = { [manufacturer: string]: { [month: string]: number } };
export function getTopK(data: CustomPivotData, topK: number): string[]  {
  const totalValuesPerLevel: { [kpiLevel: string]: number } = {};
  for (const item in data) {
    totalValuesPerLevel[item] = Object.values(data[item]).reduce((sum, value) => sum + value, 0);
  }

  // Sort CustomPivotData based on total sales volume
  const sortedLevels = Object.keys(totalValuesPerLevel)
    .sort((a, b) => totalValuesPerLevel[b] - totalValuesPerLevel[a]);

  // Select top N manufacturers
  if (topK == 0) {
    topK = sortedLevels.length;
  }
  return sortedLevels.slice(0, topK)
}

export function addOthersObject(dataArray) {
  // Calculate the length of the data arrays
  const dataLength = dataArray[0].data.length;

  // Initialize an array to store the sum of each index
  const sums = Array(dataLength).fill(0);

  // Calculate the sum of each index across all objects
  dataArray.forEach(obj => {
    obj.data.forEach((value, index) => {
      sums[index] += value;
    });
  });

  // Calculate the 'OTHERS' array by subtracting the sum from 100 for each index
  const othersData = sums.map(sum => 100 - sum);

  // Create the 'OTHERS' object
  const othersObject = {
    name: 'OTHERS',
    data: othersData
  };

  // Append the 'OTHERS' object to the original array
  dataArray.push(othersObject);

  return dataArray;
}
