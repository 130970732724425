// base.component.ts
import {DatasetProviderService} from "../services/dataset-provider.service";
import {DataService} from "../services/filters.service";
import {cacheKey, cacheKeyActiveFilterGroup} from "../utils/constants";

export class BaseComponent {
  mDataset: any[] = [];

  constructor(
    protected datasetService: DatasetProviderService,
    protected dataService: DataService,
  ) {
  }

  initializers(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.datasetService.readCSVFile('assets/data/fulldataset.csv').subscribe({
        next: (data) => {
          this.mDataset = data;
          resolve();
        },
        error: (error) => {
          console.error('Error fetching or parsing CSV file:', error);
          reject(error);
        }
      });
    });
  }

  getMBDFilterForInfoDisplay() {
    const currentGroup = this.dataService.getData(cacheKeyActiveFilterGroup);

    if (currentGroup && this.dataService.hasData(cacheKey)) {
      const selectedFilters = this.dataService.getArrayData(cacheKey);
      if (selectedFilters.length > 0) {
        return `(${currentGroup}: ${selectedFilters.join(', ')})`;
      }
    }

    return '';
  }
}
