import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../services/filters.service';
import { cacheDateKey } from '../utils/constants';


@Component({
  selector: 'app-month-picker-dialog',
  templateUrl: './month-picker-dialog.component.html',
  styleUrls: ['./month-picker-dialog.component.scss']
})
export class MonthPickerDialogComponent {
  currentYear = new Date().getFullYear();
  selectedStartMonth: { year: number, month: number } | null = null;
  selectedEndMonth: { year: number, month: number } | null = null;
  selectingEnd = false;
  months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  constructor(
    public dialogRef: MatDialogRef<MonthPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService
  ) {}

  changeYear(offset: number): void {
    this.currentYear += offset;
  }

  selectMonth(monthIndex: number): void {
    const selection = { year: this.currentYear, month: monthIndex };
    if (this.selectingEnd) {
      this.selectedEndMonth = selection;
      this.selectingEnd = false;
      this.dataService.setData(cacheDateKey, 'Date: ' + this.getSelectedRange());
      this.dialogRef.close({
        start: this.selectedStartMonth,
        end: this.selectedEndMonth
      });
    } else {
      this.selectedStartMonth = selection;
      this.selectingEnd = true;
    }
  }

  getSelectedRange(): string {
    if (!this.selectedStartMonth || !this.selectedEndMonth) {
      return 'Year-To-Date';
    }
    const startMonthName = this.months[this.selectedStartMonth.month];
    const endMonthName = this.months[this.selectedEndMonth.month];
    return `${startMonthName} ${this.selectedStartMonth.year} - ${endMonthName} ${this.selectedEndMonth.year}`;
  }

  resolvePredefinedRange(range: string): void {
    const now = new Date();
    let startMonth: number, startYear: number, endMonth: number, endYear: number;

    switch (range.toLowerCase()) {
      case 'last 3 months':
        endMonth = now.getMonth();
        endYear = now.getFullYear();
        startMonth = endMonth - 2;
        startYear = endYear;
        if (startMonth < 0) {
          startMonth += 12;
          startYear -= 1;
        }
        break;
      case 'last 6 months':
        endMonth = now.getMonth();
        endYear = now.getFullYear();
        startMonth = endMonth - 5;
        startYear = endYear;
        if (startMonth < 0) {
          startMonth += 12;
          startYear -= 1;
        }
        break;
      case 'last 12 months':
        endMonth = now.getMonth();
        endYear = now.getFullYear();
        startMonth = endMonth - 11;
        startYear = endYear;
        if (startMonth < 0) {
          startMonth += 12;
          startYear -= 1;
        }
        break;
      case 'year-to-date':
        startMonth = 0;
        startYear = now.getFullYear();
        endMonth = now.getMonth();
        endYear = startYear;
        break;
      case 'last year':
        startMonth = 0;
        startYear = now.getFullYear() - 1;
        endMonth = 11;
        endYear = startYear;
        break;
      default:
        return;
    }

    this.selectedStartMonth = { year: startYear, month: startMonth };
    this.selectedEndMonth = { year: endYear, month: endMonth };
    this.dataService.setData(cacheDateKey, 'Date: ' + range);
    this.dialogRef.close({
      start: this.selectedStartMonth,
      end: this.selectedEndMonth
    });
  }
}
