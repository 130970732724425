<div class="filter-container">
  <form [formGroup]="filterForm" class="mform">
    <div class="form-group">
      <label for="kpiCategory"><span style="color: red">* </span>KPI Category</label>
      <ng-select id="kpiCategory" formControlName="kpiCategory" [items]="kpiCategories" bindLabel="category" bindValue="category" placeholder="-- Select KPI Category --">
      </ng-select>
    </div>

    <div class="form-group" *ngIf="kpis.length > 0">
      <label for="kpi"><span style="color: red">* </span>KPI</label>
      <ng-select id="kpi" formControlName="kpi" [items]="kpis" bindLabel="label" bindValue="value" placeholder="-- Select KPI --">
      </ng-select>
    </div>

    <div class="form-group" *ngIf="filterForm.get('kpi')?.value">
      <label for="filterLevel"><span style="color: red">* </span>Filter Level</label>
      <ng-select id="filterLevel" formControlName="filterLevel" [items]="filterLevels" bindLabel="level" bindValue="value" placeholder="-- Select Filter Level --">
      </ng-select>
    </div>

    <div class="form-group" *ngIf="filterForm.get('filterLevel')?.value">
      <label for="specificFilter">Select {{ filterForm.get('filterLevel')?.value | titlecase }}</label>
      <ng-select id="specificFilter" formControlName="specificFilter" [items]="currentFilterOptions" bindLabel="label" bindValue="value" placeholder="-- Select --">
      </ng-select>
    </div>

    <div class="form-group" *ngIf="filterForm.get('filterLevel')?.value">
      <label for="notitle">&nbsp;</label>
      <button type="button" class="toggle-button" id="notitle" (click)="applyFilters()">
        Apply
      </button>
    </div>
  </form>

</div>
