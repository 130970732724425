import {abbreviateNumber, addOthersObject, valueToPercentage} from "./data_wranggler";


export function initChart(data: any[], categories: any[], numberFormat: string, isPercentage,) {
  return {
    series: data,
    chart: {
      height: 400,
      type: "area",
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -100,
          offsetY: 0
        }
      }
    }],
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        if (isPercentage) {
          return valueToPercentage(val);
        } else {
          return abbreviateNumber(val, numberFormat);
        }
      }
    },
    noData: {
      text: 'No data found for filter selection'
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    xaxis: {
      categories: categories,
      title: {
        text: 'Period'
      },
    },
    yaxis: {
      labels: {
        formatter: function (val, index) {
          if (isPercentage) {
            return valueToPercentage(val);
          } else {
            return abbreviateNumber(val, numberFormat);
          }
        }
      },
      title: {
        text: ''
      },
      min: 0,
    },
  };
}

export function initTreemapChart(data: { [key: string]: { [key: string]: number } }) {
  const preSeries = Object.keys(data[Object.keys(data)[0]]).map(manufacturer => ({
    name: manufacturer,
    data: Object.keys(data).map(month => data[month][manufacturer] || 0)
  }));

  const series = addOthersObject(preSeries)

  return {
    series: series,
    chart: {
      type: 'bar',
      height: 400,
      stacked: true
    },
    xaxis: {
      categories: Object.keys(data),
      title: {
        text: 'Period'
      }
    },
    yaxis: {
      title: {
        text: 'Percentage Contribution'
      },
      labels: {
        formatter: function (val) {
          return `${parseFloat(val).toFixed(2)}%`
        }
      },
      max: 100,
      min: 0
    },
    noData: {
      text: 'No data found for filter selection'
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${parseFloat(val).toFixed(2)}%`
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    tooltip: {
      y: {
        formatter: (val) => `${parseFloat(val).toFixed(2)}%`
      }
    },
    legend: {
      position: 'top'
    },
    fill: {
      opacity: 1
    }
  };
}
