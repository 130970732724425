export const cacheDateKey = '1chart_date_filter';
export const cacheKey = '1chart_filters';
export const cacheTotalCountryKey = '1total_country_filter';
export const cacheKeyActiveFilterGroup = '1active_filter_group';
export const cacheKeyChartNumberFormat = '1chart_number_format';
export const cacheKeySelectedCategory = '1selected_category';
export const cacheKeySelectedSegment = '1selected_segment';
export const cacheKeySelectedManufacturer = '1selected_manufacturer'
export const cacheKeySelectedBrand = '1selected_brand'
export const cacheKeyDataset = '1dataset'
export const cacheKeySelectedCountry = '1selected_country'
export const cacheKeyCustomReportConfig = '1custom_report_config'
export const cacheKeySelectedKpiLevelOptions = '1selected_kpi_level'
export const cacheKeyTopKItems = '1top_k_items'
