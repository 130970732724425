import {Component, ElementRef, OnInit, Renderer2, ViewChild,} from '@angular/core';
import {DataService} from "../../services/filters.service";
import {Subscription} from "rxjs";
import {cacheDateKey, cacheKey, cacheTotalCountryKey, cacheKeyActiveFilterGroup} from "../../utils/constants";
import {MonthPickerComponent} from "../../month-picker/month-picker.component";

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {
  regionOptions = ['Accra_Tema', 'Kumasi', 'Takoradi', 'Tamale', 'Rest of Ghana'];
  channelOptions = ['Groceries', 'Mini_Stores', 'Open_Market', 'Table_Tops'];
  currentGroup: string = '';
  filterKeys = ['Region', 'Channel', 'Country'];
  storageSubscription: Subscription;
  selectedFilters: string[] = [];
  chartDateFilter: string;
  filterDate: string
  initialState = {
    'Region': false,
    'Channel': false,
    'Country': false
  };
  openState = this.initialState
  isToggled: boolean = false;

  @ViewChild(MonthPickerComponent) monthPicker: MonthPickerComponent;

  toggleButton(): void {
    if (this.currentGroup == '') {
      return
    }
    this.isToggled = !this.isToggled;
    this.dataService.setData(cacheTotalCountryKey,  this.isToggled);
    if (this.isToggled) {
      this.currentGroup = ''
      this.dataService.setData(cacheKeyActiveFilterGroup, '');
      this.selectedFilters = ['Total Country'];
      this.dataService.setArrayData(cacheKey, this.selectedFilters);
    }
  }

  closeFilterDropdowns() {
    this.filterKeys.forEach(key => {
      this.openState[key] = false;
    });
  }

  toggleDropdown1(label: string): void {
    this.filterKeys.forEach(key => {
      if (key !== label) {
        this.openState[key] = false;
      }
    });
    this.monthPicker.closeMonthPickerDropDown();
    this.openState[label] = !this.openState[label];
  }

  isItemChecked(item: string) {
    return this.selectedFilters.includes(item);
  }

  applyFilters() {
    this.filterKeys.forEach(key => {
      this.openState[key] = false;
    })
    if (this.selectedFilters.length === 0) {
      this.toggleButton();
      return
    }
    this.dataService.setArrayData(cacheKey, this.selectedFilters);
    this.isToggled = false;
    this.dataService.setData(cacheTotalCountryKey,  false);
  }

  onSelect(group: string, label: string,) {
    this.currentGroup = group;
    if (group !== this.dataService.getData(cacheKeyActiveFilterGroup)) {
      this.selectedFilters = [];
      this.dataService.setData(cacheKeyActiveFilterGroup, group);
      this.dataService.setArrayData(cacheKey, this.selectedFilters);
    }
    if (!this.selectedFilters.includes(label)) {
      this.selectedFilters.push(label);
    } else {
      const index = this.selectedFilters.indexOf(label);
      if (index > -1) {
        this.selectedFilters.splice(index, 1);
      }
    }
  }

  isButtonReallyToggled() {
    return this.currentGroup === ''
  }

  constructor(private dataService: DataService) {
  }

  async updateFilters() {
    if (this.dataService.hasData(cacheKey)) {
      this.selectedFilters = this.dataService.getArrayData(cacheKey);
    } else {
      this.selectedFilters = [];
    }

    if (this.dataService.hasData(cacheDateKey)) {
      this.chartDateFilter = this.dataService.getData(cacheDateKey);
      this.filterDate = this.chartDateFilter.split(': ')[1];
    } else {
      this.chartDateFilter = 'Period: Year-To-Date';
      this.filterDate = 'Year-To-Date';
    }

    if (this.dataService.hasData(cacheTotalCountryKey)) {
      this.isToggled = this.dataService.getBooleanData(cacheTotalCountryKey);
    }

  }
  ngOnInit() {
    this.updateFilters();
    this.currentGroup = this.dataService.getData(cacheKeyActiveFilterGroup);
    this.storageSubscription = this.dataService.watchStorage().subscribe((data) => {
      if (data.key === cacheDateKey || data.key === cacheKey) {
        this.updateFilters();
      }
    });
  }
}
