import {Component, Input, OnInit} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {DataService} from "../services/filters.service";
import {cacheDateKey} from "../utils/constants";
import {MonthPickerDialogComponent} from "../month-picker-dialog/month-picker-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {findMinMaxYears} from "../utils/data_wranggler";
import {DatasetProviderService} from "../services/dataset-provider.service";
import {BaseComponent} from "../base/base.component";

@Component({
  selector: 'app-month-picker',
  standalone: true,
  imports: [
    NgForOf,
    FormsModule,
    NgIf,
    NgOptimizedImage
  ],
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss']
})
export class MonthPickerComponent extends BaseComponent implements OnInit {
  isDropdownOpen = false;
  currentYear = new Date().getFullYear();
  thisYear = new Date().getFullYear();
  minYear = 2000;
  maxYear = 2100;
  selectedStartMonth: { year: number, month: number } | null = null;
  selectedEndMonth: { year: number, month: number } | null = null;
  selectingEnd = false; // Track whether we are selecting the start or end month
  months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  dataset: any[] = []

  @Input() closeFilterDropdowns: () => void;

  closeMonthPickerDropDown() {
    this.isDropdownOpen = false;
  }

  constructor(dataService: DataService, public dialog: MatDialog, datasetService: DatasetProviderService) {
    super(datasetService, dataService);
  }

  toggleDropdown(): void {
    if (window.innerWidth <= 768) {
      this.openDialog();
    } else {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen){
        this.closeFilterDropdowns();
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MonthPickerDialogComponent, {
      width: '300px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedStartMonth = result.start;
        this.selectedEndMonth = result.end;
      }
    });
  }

  changeYear(offset: number): void {
    if (this.currentYear + offset <= this.maxYear && this.currentYear + offset >= this.minYear) {
      this.currentYear += offset;
    }
  }

  showPredefinedRange(range: string): boolean {
    switch (range) {
      case 'Year-To-Date':
      case 'Last 3 Months':
      case 'Last 6 Months':
        return this.thisYear <= this.maxYear && this.thisYear >= this.minYear;
      case 'Last 12 Months':
      case 'Last Year':
        return this.thisYear-1 <= this.maxYear && this.thisYear-1 >= this.minYear;
      default:
        return false;
    }
  }

  selectMonth(monthIndex: number): void {
    const selection = { year: this.currentYear, month: monthIndex };
    if (this.selectingEnd) {
      this.selectedEndMonth = selection;
      this.isDropdownOpen = false;
      this.selectingEnd = false;
      this.dataService.setData(cacheDateKey, 'Period: ' + this.getSelectedRange());
    } else {
      this.selectedStartMonth = selection;
      this.selectingEnd = true;
    }
  }

  getSelectedRange(): string {
    if (!this.selectedStartMonth || !this.selectedEndMonth) {
      return 'Year-To-Date';
    }
    const startMonthName = this.months[this.selectedStartMonth.month];
    const endMonthName = this.months[this.selectedEndMonth.month];
    return `${startMonthName} ${this.selectedStartMonth.year} - ${endMonthName} ${this.selectedEndMonth.year}`;
  }

  ngOnInit() {
    this.initializers().then(() => {
      this.dataset = this.mDataset;
      const minmax = findMinMaxYears(this.dataset);
      this.minYear = minmax.minYear;
      this.maxYear = minmax.maxYear;
    })
  }

  resolvePredefinedRange(range: string): void {
    const now = new Date();
    let startMonth: number, startYear: number, endMonth: number, endYear: number;

    switch (range.toLowerCase()) {
      case 'last 3 months':
        endMonth = now.getMonth();
        endYear = now.getFullYear();
        startMonth = endMonth - 2;
        startYear = endYear;
        if (startMonth < 0) {
          startMonth += 12;
          startYear -= 1;
        }
        break;
      case 'last 6 months':
        endMonth = now.getMonth();
        endYear = now.getFullYear();
        startMonth = endMonth - 5;
        startYear = endYear;
        if (startMonth < 0) {
          startMonth += 12;
          startYear -= 1;
        }
        break;
      case 'last 12 months':
        endMonth = now.getMonth();
        endYear = now.getFullYear();
        startMonth = endMonth - 11;
        startYear = endYear;
        if (startMonth < 0) {
          startMonth += 12;
          startYear -= 1;
        }
        break;
      case 'year-to-date':
        startMonth = 0;
        startYear = now.getFullYear();
        endMonth = now.getMonth();
        endYear = startYear;
        break;
      case 'last year':
        startMonth = 0;
        startYear = now.getFullYear() - 1;
        endMonth = 11;
        endYear = startYear;
        break;
      default:
        return;
    }

    this.selectedStartMonth = { year: startYear, month: startMonth };
    this.selectedEndMonth = { year: endYear, month: endMonth };
    this.isDropdownOpen = false;
    this.selectingEnd = false;

    this.dataService.setData(cacheDateKey, 'Period: ' + range);
  }
}
