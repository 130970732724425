import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FilterBarComponent} from "../../components/common/filter-bar.component";
import { DateRangePickerComponent } from '../../date-range-picker/date-range-picker.component';
import {SharedModule} from "./shared.module";
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DashboardChartComponent } from '../../dashboard-chart/dashboard-chart.component';
import {MonthPickerComponent} from "../../month-picker/month-picker.component";
import {MonthPickerDialogComponent} from "../../month-picker-dialog/month-picker-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {ReportsComponentComponent} from "../../reports-component/reports-component.component";



const imports = [
  ReactiveFormsModule,
  NgxDaterangepickerMd.forRoot()
];
@NgModule({
  declarations: [
    FilterBarComponent,
    DateRangePickerComponent,
    DashboardChartComponent,
    MonthPickerDialogComponent
  ],
  exports: [
    FilterBarComponent,
    DashboardChartComponent
  ],
    imports: [
        CommonModule,
        ...imports,
        SharedModule,
        NgOptimizedImage,
        MatDialogModule,
        MonthPickerComponent,
        ReportsComponentComponent
    ],

})
export class AppModule { }
