import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Papa from 'papaparse';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DatasetProviderService {
  constructor(private http: HttpClient) {}

  readCSVFile(url: string): Observable<any[]> {
    return this.http.get(url, { responseType: 'text' }).pipe(
      map(csvData => this.parseCsv(csvData))
    );
  }

  private parseCsv(csvData: string): any[] {
    let parsedData: any[] = [];
    const preprocessedData = this.preprocessCsvData(csvData);

    Papa.parse(preprocessedData, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        parsedData = result.data;
      }
    });
    return parsedData;
  }

  private preprocessCsvData(csvData: string): string {
    // Regular expression to match content within double quotes
    const regex = /"([^"]*)"/g;

    // Replace commas inside the matched content with hyphens
    const processedData = csvData.replace(regex, (match, p1) => {
      // Replace only the commas inside the quotes, leaving the quotes intact
      const modified = p1.replace(/,/g, '-');
      return `"${modified}"`;
    });

    return processedData;
  }
}
