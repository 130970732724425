import { Component, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent {
  @Output() close = new EventEmitter<void>();
  selected: { startDate: moment.Moment, endDate: moment.Moment };
  options = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    opens: 'right'
  };

  selectedDate(value: any) {
    this.selected = value;
  }

  applyDateRange() {
    // Add logic to apply the selected date range
    console.log('Date Range:', this.selected);
    this.close.emit();
  }
}
