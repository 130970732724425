import {Component, NgModule, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {CommonModule, NgForOf, TitleCasePipe} from '@angular/common';
import {FILTER_LEVELS, FilterOption, KPI_CATEGORIES} from "./datastructure";
import {getUniqueColumnValuesFromDataset} from "../utils/data_wranggler";
import {BaseComponent} from "../base/base.component";
import {DataService} from "../services/filters.service";
import {DatasetProviderService} from "../services/dataset-provider.service";
import {cacheKeyCustomReportConfig, cacheKeySelectedKpiLevelOptions} from "../utils/constants";
import {NgSelectComponent} from "@ng-select/ng-select";

@Component({
  selector: 'app-reports-component',
  standalone: true,
  imports: [
    NgForOf,
    TitleCasePipe,
    ReactiveFormsModule,
    CommonModule,
    NgSelectComponent
  ],
  templateUrl: './reports-component.component.html',
  styleUrl: './reports-component.component.scss'
})
export class ReportsComponentComponent extends BaseComponent implements OnInit {
  filterForm: FormGroup;
  kpiCategories = KPI_CATEGORIES;
  filterLevels = FILTER_LEVELS;
  dataset: any[] = [];

  kpis: FilterOption[] = [];
  currentFilterOptions: FilterOption[] = [];

  constructor(
    private fb: FormBuilder,
    datasetService: DatasetProviderService,
    dataService: DataService,
  ) {
    super(datasetService, dataService);
    this.filterForm = this.fb.group({
      kpiCategory: '',
      kpi: '',
      filterLevel: '',
      specificFilter: '',
    });

    //this.kpis = this.kpiCategories[0].kpis;

    this.onKpiCategoryChange();
    this.onKpiChange();
    this.onFilterLevelChange();
  }

  onKpiCategoryChange() {
    this.filterForm.get('kpiCategory')?.valueChanges.subscribe(selectedCategory => {
      const selectedGroup = this.kpiCategories.find(group => group.category === selectedCategory);
      this.kpis = selectedGroup ? selectedGroup.kpis : [];
      const firstKpi = this.kpis.length > 0 ? this.kpis[0].value : '';
      this.filterForm.patchValue({ kpi: firstKpi, filterLevel: '', specificFilter: '' });
    });
  }

  onKpiChange() {
    this.filterForm.get('kpi')?.valueChanges.subscribe(selectedKpi => {
      if (selectedKpi === 'unit_price') {
        // Show only -- select -- and sku
        this.filterLevels = FILTER_LEVELS.filter(level => level.value === '' || level.value === 'item_name');
      } else {
        // Show -- select -- and everything except sku
        this.filterLevels = FILTER_LEVELS.filter(level => level.value !== 'item_name');
      }
      const firstFilterLevel = this.filterLevels.length > 0 ? this.filterLevels[0].value : '';
      this.filterForm.patchValue({ filterLevel: firstFilterLevel, specificFilter: '' });
    });
  }

  async onFilterLevelChange() {
    this.filterForm.get('filterLevel')?.valueChanges.subscribe(selectedLevel => {
      if (selectedLevel) {
        const dataCategoryOptions = getUniqueColumnValuesFromDataset(this.dataset, selectedLevel);
        this.currentFilterOptions = dataCategoryOptions.map(item => ({ value: item, label: item }));
        this.currentFilterOptions.unshift({ value: 'all', label: 'All' });
        this.dataService.setArrayData(cacheKeySelectedKpiLevelOptions, this.currentFilterOptions, false);
        const firstOption = this.currentFilterOptions.length > 0 ? this.currentFilterOptions[0].value : '';
        this.filterForm.patchValue({ specificFilter: firstOption });
      }
    });
  }

  ngOnInit() {
    this.initializers().then(() => {
      this.dataset = this.mDataset;
    })

    // Initialize with the first available KPI category
    const firstCategory = this.kpiCategories.length > 0 ? this.kpiCategories[0].category : '';
    this.filterForm.patchValue({ kpiCategory: firstCategory });

  }

  applyFilters() {
    const selectedValues = {
      kpiCategory: this.filterForm.get('kpiCategory')?.value,
      kpi: this.filterForm.get('kpi')?.value,
      filterLevel: this.filterForm.get('filterLevel')?.value,
      specificFilter: this.filterForm.get('specificFilter')?.value,
    };

    this.dataService.setObjectData(cacheKeyCustomReportConfig, selectedValues);
  }
}
