<div>
<div class="filter-bar">
  <div class="filter-icon"><img ngSrc="assets/images/svg/filter-icon.svg" alt="Expand" height="25" width="24"></div>
<div style="display: flex; justify-content: space-between; width: 100%">
  <div>
    <app-month-picker [closeFilterDropdowns]="closeFilterDropdowns.bind(this)"></app-month-picker>
  </div>
  <div>
  <div class="dropdown-container">
  <button (click)="toggleButton()" [ngClass]="{ 'toggled': isToggled }" class="toggle-button">
    Total Country
  </button>
    <div *ngIf="isButtonReallyToggled()" style="background-color: red; width: 100%; height: 2px"></div>
  </div>
  <div class="dropdown-container" [class.open]="openState['Region']">
    <button (click)="toggleDropdown1('Region')" class="mdropdown-toggle">
      <div *ngIf="currentGroup==='Region'" class="active-filter-indicator">
        {{selectedFilters.length > 9? '9+' : selectedFilters.length}}
      </div>
      Region
      <img ngSrc="assets/images/svg/chev-down.svg" alt="Expand" height="25" width="24">
      <!--      <i [ngClass]="{'fa fa-chevron-up': isOpen, 'fa fa-chevron-down': !isOpen}"></i>-->
    </button>
    <div *ngIf="currentGroup==='Region'" style="background-color: red; width: 100%; height: 2px"></div>
    <div class="mdropdown-menu" *ngIf="openState['Region']">
      <div *ngFor="let option of regionOptions" class="dropdown-item" (click)="onSelect('Region', option)">
        <input type="checkbox" [checked]="isItemChecked(option)" [value]="option"> {{option}}
      </div>
      <a style="margin-top: 20px; color: #0b7cc5; cursor: pointer" (click)="applyFilters()">Apply</a>
    </div>
  </div>
  <div class="dropdown-container" [class.open]="openState['Channel']">
    <button (click)="toggleDropdown1('Channel')" class="mdropdown-toggle">
      <div *ngIf="currentGroup==='Channel'" class="active-filter-indicator">
        {{selectedFilters.length > 9? '9+' : selectedFilters.length}}
      </div>
      Channel
      <img ngSrc="assets/images/svg/chev-down.svg" alt="Expand" height="25" width="24">
      <!--      <i [ngClass]="{'fa fa-chevron-up': isOpen, 'fa fa-chevron-down': !isOpen}"></i>-->
    </button>
    <div *ngIf="currentGroup==='Channel'" style="background-color: red; width: 100%; height: 2px"></div>
    <div class="mdropdown-menu" *ngIf="openState['Channel']">
        <div *ngFor="let option of channelOptions" class="dropdown-item" (click)="onSelect('Channel', option)">
        <input type="checkbox" [checked]="isItemChecked(option)" [value]="option"> {{option}}
      </div>
      <a style="margin-top: 20px; color: #0b7cc5; cursor: pointer" (click)="applyFilters()">Apply</a>
    </div>
  </div>
  </div>
</div>
</div>
  <div class="filter-chips-container">
    <div class="filter-chip">
      {{chartDateFilter}}
    </div>
    <div *ngIf="currentGroup != '' && currentGroup != null" class="filter-chip">
      {{currentGroup}}: {{selectedFilters.join(', ')}}
    </div>
  </div>
</div>
