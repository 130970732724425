<div class="date-range-picker">
  <div class="header">
    <span>Select Date Range</span>
    <button (click)="close.emit()">✖</button>
  </div>
  <div class="calendar">
    <input type="text" ngxDaterangepickerMd [(ngModel)]="selected" class="form-control"/>
<!--    <input type="text" daterangepicker [(ngModel)]="selected" [options]="options" (selected)="selectedDate($event)">-->
  </div>
  <div class="actions">
    <button (click)="applyDateRange()">Apply</button>
  </div>
</div>
