import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import {
  createPivotTable,
  pivotObjectToChartArray,
  pivotObjectKeysToArray,
  filterDataByDateRange,getUniqueColumnValuesFromDataset
} from "../utils/data_wranggler";
import { initChart } from "../utils/chartUtils";
import { DataService } from "../services/filters.service";
import { Subscription } from "rxjs";
import {
  cacheDateKey,
  cacheKey,
  cacheKeyChartNumberFormat,
  cacheKeyDataset,
  cacheKeySelectedCategory, cacheKeySelectedCountry
} from "../utils/constants";
import {DatasetProviderService} from "../services/dataset-provider.service";
import {BaseComponent} from "../base/base.component";

@Component({
  selector: 'app-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.scss'],
})
export class DashboardChartComponent extends BaseComponent implements OnInit, OnDestroy {
  tabs = ['Sales Volume (KG/L)', 'Sales Value (GHS)', 'Average Price (GHS/KG)', 'Category ND (%)'];
  chartNumberFormatOptions = ['Absolute', 'Thousand', 'Million', 'Billion', 'Trillion'];
  dataCategoryOptions = [];
  activeTabName = 'Sales Volume (KG/L)';
  storageSubscription: Subscription;
  public pivotTableSalesVolumeChart;
  public pivotTableSalesValueChart;
  public pivotTablePriceChart;
  public pivotTableCatNdChart;
  pivotTableSalesVolume;
  pivotTableSalesValue;
  pivotTablePrice;
  pivotTableCatNd;
  chartFilters: any;
  chartDateFilter: string;
  filterData: string[];
  filterDate: string;
  chartNumberFormat = 'Absolute';
  selectedCategory = '';
  selectedCountry = ''
  dataset: any[] = [];
  isLoading = true;

  constructor(
    private title: Title,
    dataService: DataService,
    datasetService: DatasetProviderService
  ) {
    super(datasetService, dataService);
  }

  async updateFilters() {
    this.isLoading = true;
    if (this.dataService.hasData(cacheKey)) {
      this.chartFilters = this.dataService.getArrayData(cacheKey);
      this.filterData = this.chartFilters;
    } else {
      this.chartFilters = 'Country: Total Country';
      this.filterData = [];
    }

    if (this.dataService.hasData(cacheDateKey)) {
      this.chartDateFilter = this.dataService.getData(cacheDateKey);
      this.filterDate = this.chartDateFilter.split(': ')[1];
    } else {
      this.chartDateFilter = 'Period: Year-To-Date';
      this.filterDate = 'Year-To-Date';
    }

    if (this.dataService.hasData(cacheKeyChartNumberFormat)) {
      this.chartNumberFormat = this.dataService.getData(cacheKeyChartNumberFormat);
    }
    this.dataCategoryOptions = getUniqueColumnValuesFromDataset(this.dataset, 'category');
    if (this.dataService.hasData(cacheKeySelectedCategory)) {
      this.selectedCategory = this.dataService.getData(cacheKeySelectedCategory);
    } else {
      this.selectedCategory = this.dataCategoryOptions[0];
    }

    if (this.dataService.hasData(cacheKeySelectedCountry)) {
      this.selectedCountry = this.dataService.getData(cacheKeySelectedCountry);
    } else {
      const dataCountryOptions = getUniqueColumnValuesFromDataset(this.dataset, 'country');
      this.selectedCountry = dataCountryOptions[0]
    }

    const filteredDataset = this.dataset.filter(item => {
      return item.country === this.selectedCountry
    });

    this.pivotTableSalesVolume = createPivotTable(filteredDataset, 'category', 'period', 'sales_volume(kg_ltrs)', 'MBD', this.filterData, 'sum');
    this.pivotTableSalesValue = createPivotTable(filteredDataset, 'category', 'period', 'sales_value', 'MBD', this.filterData, 'sum');
    this.pivotTablePrice = createPivotTable(filteredDataset, 'category', 'period', 'price(kg_ltr)', 'MBD', this.filterData, 'avg');
    this.pivotTableCatNd = createPivotTable(filteredDataset, 'category', 'period', 'cat_nd', 'MBD', this.filterData, 'sum');


    this.onFilter();
  }

  ngOnInit() {
    this.initializers().then(() => {
      this.dataset = this.mDataset;
      this.updateFilters();
    })
    this.storageSubscription = this.dataService.watchStorage().subscribe((data) => {
      this.updateFilters();
    });
  }

  ngOnDestroy(): void {
    if (this.storageSubscription) {
      this.storageSubscription.unsubscribe();
    }
  }

  onFilter(params = null) {
    this.pivotTableSalesVolumeChart = this.forAllCategories(this.pivotTableSalesVolume, 'Sales Volume', false)
    this.pivotTableSalesValueChart = this.forAllCategories(this.pivotTableSalesValue, 'Sales Value', false)
    this.pivotTablePriceChart = this.forAllCategories(this.pivotTablePrice, 'Price', false)
    this.pivotTableCatNdChart = this.forAllCategories(this.pivotTableCatNd, 'Category ND', true)

    this.isLoading = false;
  }

  forAllCategories(param, seriesName, isPercentage){
    const stuff = [];
    if (this.selectedCategory === 'All') {
      const tempArray = this.dataCategoryOptions.slice();
      tempArray.shift()
      for (const category of tempArray) {
        let wrapper: any = {};
        let obj = filterDataByDateRange(param, this.filterDate, category);
        wrapper.data = obj
        wrapper.seriesName = category
        stuff.push(wrapper);
      }
    } else {
      let wrapper: any = {};
      let obj = filterDataByDateRange(param, this.filterDate, this.selectedCategory);
      wrapper.data = obj
      wrapper.seriesName = this.selectedCategory
      stuff.push(wrapper);
    }

    const chartData = {
      data: pivotObjectToChartArray(stuff),
      categories: pivotObjectKeysToArray(stuff)
    };

    return initChart(
      chartData.data,
      chartData.categories,
      this.chartNumberFormat,
      isPercentage,
    );
  }

  selectTab(event: Event) {
    const selectedTab = (event.target as HTMLSelectElement).value;
    this.activeTabName = selectedTab;
  }

  setChartNumberFormat(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.chartNumberFormat = selectedValue;
    this.dataService.setData(cacheKeyChartNumberFormat, selectedValue);
  }

  setCategoryOption(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.selectedCategory = selectedValue;
    this.dataService.setData(cacheKeySelectedCategory, selectedValue);
  }

  getInfoText() {
    return `You are viewing <b>${this.activeTabName}</b> data at the <b>category</b> level for the <b>${this.selectedCategory}</b> category in <b>${this.selectedCountry} ${this.getMBDFilterForInfoDisplay()}</b>`
  }

}
