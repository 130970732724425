<div style="display: flex; justify-content: center; align-items: center">
  <div *ngIf="isLoading" class="loader"></div>
</div>
<div  *ngIf="!isLoading">
  <svg xmlns="http://www.w3.org/2000/svg" class="d-none">
  <symbol id="info-fill" viewBox="0 0 16 16">
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
  </symbol>
</svg>
<div class="info-chip">
  <svg  class="bi flex-shrink-0 me-2 info-chip-icon" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg>
  <div>
    <div [innerHTML]="getInfoText()"></div>
  </div>
</div>
<div class="chart-container">
  <div class="tabs">
    <div class="number-format-selector">
      <div class="tab">
      <label for="tab-dropdown" class="chart-setting-label">Market KPI</label>
      <select
        id="tab-dropdown"
        class="chart-setting-select"
        [(ngModel)]="activeTabName"
        (change)="selectTab($event)"
      >
        <option
          *ngFor="let tab of tabs; let i = index"
          [value]="tab"
          [selected]="tab === activeTabName"
        >
          {{ tab }}
        </option>
      </select>
    </div>
    </div>
    <div class="number-format-selector">
      <div>
        <label for="formats" class="chart-setting-label">Number Format</label>
        <select id="formats" class="chart-setting-select" [(ngModel)]="chartNumberFormat" (change)="setChartNumberFormat($event)">
          <option *ngFor="let option of chartNumberFormatOptions; let i = index" [value]="option" [selected]="option === chartNumberFormat">{{option}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="chart-content">
    <div class="chart-ve" *ngIf="activeTabName === 'Sales Volume (KG/L)'">
      <div class="chartjs" style="width: 100%">
        <apx-chart [series]="pivotTableSalesVolumeChart.series"
                   [chart]="pivotTableSalesVolumeChart.chart"
                   [xaxis]="pivotTableSalesVolumeChart.xaxis"
                   [yaxis]="pivotTableSalesVolumeChart.yaxis"
                   [title]="pivotTableSalesVolumeChart.title"
                   [dataLabels]="pivotTableSalesVolumeChart.dataLabels"
        ></apx-chart>
      </div>
    </div>
    <div class="chart-ve" *ngIf="activeTabName === 'Sales Value (GHS)'">
      <div class="chartjs" style="width: 100%">
        <apx-chart [series]="pivotTableSalesValueChart.series"
                   [chart]="pivotTableSalesValueChart.chart"
                   [xaxis]="pivotTableSalesValueChart.xaxis"
                   [yaxis]="pivotTableSalesValueChart.yaxis"
                   [title]="pivotTableSalesValueChart.title"
                   [dataLabels]="pivotTableSalesValueChart.dataLabels"
        ></apx-chart>
      </div>
    </div>
    <div class="chart-ve" *ngIf="activeTabName === 'Average Price (GHS/KG)'">
      <div class="chartjs" style="width: 100%">
        <apx-chart [series]="pivotTablePriceChart.series"
                   [chart]="pivotTablePriceChart.chart"
                   [xaxis]="pivotTablePriceChart.xaxis"
                   [yaxis]="pivotTablePriceChart.yaxis"
                   [title]="pivotTablePriceChart.title"
                   [dataLabels]="pivotTablePriceChart.dataLabels"
        ></apx-chart>
      </div>
    </div>
    <div class="chart-ve" *ngIf="activeTabName === 'Category ND (%)'">
      <div class="chartjs" style="width: 100%">
        <apx-chart [series]="pivotTableCatNdChart.series"
                   [chart]="pivotTableCatNdChart.chart"
                   [xaxis]="pivotTableCatNdChart.xaxis"
                   [yaxis]="pivotTableCatNdChart.yaxis"
                   [title]="pivotTableCatNdChart.title"
                   [dataLabels]="pivotTableCatNdChart.dataLabels"
        ></apx-chart>
      </div>
    </div>
  </div>
</div>
</div>
